div.bhdLno{
  max-width: 1000px;
  max-height: 1000px;
  width: 40vw;
  height: 40vw;
}

@media screen and (max-width: 768px) {
  div.bhdLno{
    width: 80vw;
    height: 80vw;
  }
}

.wheel-wrapper{
  cursor: pointer;
}