@import '~bootstrap/scss/bootstrap';

// $primary: #ff6347; // Custom primary color

.primary-button{
    background-color: $blue-700;
}

.primary-button:hover {
    background-color: $blue-600;
}

.primary-button:disabled {
    background-color: $blue-300;
    opacity: 1;
}